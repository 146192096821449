import { MobileVerticalLineSVG } from 'components/svg/svg';
import './HumbleList.scss';

function MobileHumbleList({ title, items }: { title: string; items: string[]; }) {
  return (
    <div className='humble-list'>
      <MobileVerticalLineSVG/>
      <div className='list'>
        <div className='list-title'><b>{title}</b></div>
        {items.map((i, idx) => <div key={idx}>— {i}</div>)}
      </div>
    </div>
  );
}

export default MobileHumbleList;
