import { Line } from "react-chartjs-2";

const chartData: (React.ComponentProps<typeof Line>)["data"] = {
  labels: ['JUN-24', 'JUL-24', 'AUG-24', 'SEP-24', 'OCT-24', 'NOV-24', 'DEC-24', 'JAN-25'],
  datasets: [
    {
      data: [75645901217, 82134869248, 88962249792, 95144331782, 101217101622, 113301427953, 133746259074, 151374633914],
      borderColor: '#62C759',
      borderWidth: 2,
      backgroundColor: '#26433A',
      pointStyle: 'rect',
      pointBorderWidth: 6,
      pointHoverBorderWidth: 20,
      pointHitRadius: 20,
      fill: true,
    }
  ],
};

export default chartData;
