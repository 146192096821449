import { VerticalLineSVG, EmptyVerticalLineSVG } from 'components/svg/svg';
import './HumbleList.scss';

function HumbleList({ title, items, verticalLine = true }: { title: string; items: string[]; verticalLine? : boolean }) {
  return (
    <div className='humble-list'>
      { verticalLine ? <VerticalLineSVG/> : <EmptyVerticalLineSVG/> }
      <div className='list'>
        <div className='list-title'><b>{title}</b></div>
        {items.map((i, idx) => <div key={idx}>— {i}</div>)}
      </div>
    </div>
  );
}

export default HumbleList;
